import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Alert = makeShortcode("Alert");
const ReleaseNote = makeShortcode("ReleaseNote");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <Alert type="info" mdxType="Alert">
      <p>{`Subscribe to the `}
        <a {...{
          "href": "/minfraud/release-notes/rss.xml",
          "parentName": "p"
        }}>{`minFraud release notes RSS feed`}</a>
        {`.`}</p>
    </Alert>
    <ReleaseNote date="2024-08-15" title="MaxMind minFraud alert emails now include instant feedback links" mdxType="ReleaseNote">
      <p>{`MaxMind minFraud alert emails now include links that allow you to provide
instant feedback on each request. Providing transaction feedback helps improve
the risk scoring for your account.`}</p>
      <p>{`You can mark a request as risky and we will raise the risk for similar requests.`}</p>
      <p>{`Or, you can mark a request as not risky and we will lower the risk for similar
requests.`}</p>
      <p><a {...{
          "href": "https://get.maxmind.com/hubfs/Marketing/Misc/minfraud-alerts-feedback-links.png",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Here is an example of the links`}</a>
        {`
in the minFraud alerts email body.`}</p>
      <p>{`Please continue to
`}
        <a {...{
          "href": "https://dev.maxmind.com/minfraud/report-a-transaction",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`report chargebacks and fraud on your account portal or via API.`}</a></p>
    </ReleaseNote>
    <ReleaseNote date="2024-08-08" title="Additional filtering options now available for minFraud transactions" mdxType="ReleaseNote">
      <p>{`The transactions screen in your MaxMind account portal now has expanded
filtering options, giving you added ability to discover trends in your
transactions and adjust risk strategies.`}</p>
      <p>{`You can now filter transactions by:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}><strong {...{
            "parentName": "li"
          }}>{`Risk score range`}</strong>
          {`: the likelihood that a transaction is fraudulent, scored
between a minimum of 0.01 and a maximum of 99`}</li>


        <li {...{
          "parentName": "ul"
        }}><strong {...{
            "parentName": "li"
          }}>{`IP risk score range`}</strong>
          {`: the riskiness of an IP address, scored between a
minimum of 0.01 and a maximum of 99`}</li>


        <li {...{
          "parentName": "ul"
        }}><strong {...{
            "parentName": "li"
          }}>{`ISP`}</strong>
          {`: the internet service provider of the end-user who initiated the
transaction`}</li>


        <li {...{
          "parentName": "ul"
        }}><strong {...{
            "parentName": "li"
          }}>{`IP Country`}</strong>
          {`: the country for the IP address associated with the transaction`}</li>

      </ul>
    </ReleaseNote>
    <ReleaseNote date="2024-07-09" title="New phone outputs released for minFraud® Insights and minFraud Factors" mdxType="ReleaseNote">
      <p>{`We have released additional outputs for our
`}
        <a {...{
          "href": "https://www.maxmind.com/en/solutions/minfraud-services",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`minFraud Insights and minFraud Factors`}</a>
        {`
web services. Insights and Factors customers that pass phone numbers can now
make use of the following additional outputs:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>

          <p {...{
            "parentName": "li"
          }}><inlineCode {...{
              "parentName": "p"
            }}>{`/shipping_phone/country`}</inlineCode>
            {` – A two-character ISO 3166-1 country code for the
country associated with the shipping phone number.`}</p>

        </li>


        <li {...{
          "parentName": "ul"
        }}>

          <p {...{
            "parentName": "li"
          }}><inlineCode {...{
              "parentName": "p"
            }}>{`/shipping_phone/is_voip`}</inlineCode>
            {` – This is true if the shipping phone number is a
Voice over Internet Protocol (VoIP) number allocated by a regulator. It is
false if the shipping phone number is not a VoIP number allocated by a
regulator. The key is only present when a valid shipping phone number has been
provided and we have data for it.`}</p>

        </li>


        <li {...{
          "parentName": "ul"
        }}>

          <p {...{
            "parentName": "li"
          }}><inlineCode {...{
              "parentName": "p"
            }}>{`/shipping_phone/network_operator`}</inlineCode>
            {` – The name of the original network operator
associated with the shipping phone number. This field does not reflect phone
numbers that have been ported from the original operator to another, nor does
it identify mobile virtual network operators.`}</p>

        </li>


        <li {...{
          "parentName": "ul"
        }}>

          <p {...{
            "parentName": "li"
          }}><inlineCode {...{
              "parentName": "p"
            }}>{`/shipping_phone/number_type`}</inlineCode>
            {` – Indicates whether the phone number is mobile
or fixed.`}</p>

        </li>


        <li {...{
          "parentName": "ul"
        }}>

          <p {...{
            "parentName": "li"
          }}><inlineCode {...{
              "parentName": "p"
            }}>{`/billing_phone/country`}</inlineCode>
            {` – A two-character ISO 3166-1 country code for the
country associated with the billing phone number.`}</p>

        </li>


        <li {...{
          "parentName": "ul"
        }}>

          <p {...{
            "parentName": "li"
          }}><inlineCode {...{
              "parentName": "p"
            }}>{`/billing_phone/is_voip`}</inlineCode>
            {` – This is true if the billing phone number is a Voice
over Internet Protocol (VoIP) number allocated by a regulator. It is false if
the billing phone number is not a VoIP number allocated by a regulator. The
key is only present when a valid billing phone number has been provided and we
have data for it.`}</p>

        </li>


        <li {...{
          "parentName": "ul"
        }}>

          <p {...{
            "parentName": "li"
          }}><inlineCode {...{
              "parentName": "p"
            }}>{`/billing_phone/network_operator`}</inlineCode>
            {` – The name of the original network operator
associated with the billing phone number. This field does not reflect phone
numbers that have been ported from the original operator to another, nor does
it identify mobile virtual network operators.`}</p>

        </li>


        <li {...{
          "parentName": "ul"
        }}>

          <p {...{
            "parentName": "li"
          }}><inlineCode {...{
              "parentName": "p"
            }}>{`/billing_phone/number_type`}</inlineCode>
            {` – Indicates whether the phone number is mobile or
fixed.`}</p>

        </li>

      </ul>
      <p>{`These values are particularly helpful to identify mismatches between data
points, such as a mismatch between the billing country as indicated by the IP
address and the country as indicated by the billing phone number. Another strong
signal for fraud is a phone carrier that does not operate in the country
indicated by the IP address.`}</p>
      <p>{`Our `}
        <a {...{
          "href": "/minfraud/evaluate-a-transaction#links-to-maxmind-client-apis",
          "parentName": "p"
        }}>{`client APIs`}</a>
        {`
have been updated to support these outputs so may need to refresh yours if you
are not interfacing directly with our REST API.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2024-04-08" title="Email first seen can be used as a parameter for custom rules" mdxType="ReleaseNote">
      <p>{`minFraud Insights and Factors customers can now use the
`}
        <a {...{
          "href": "https://dev.maxmind.com/minfraud/api-documentation/responses/#schema--response--email",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}><inlineCode {...{
            "parentName": "a"
          }}>{`/email/first_seen`}</inlineCode>
          {` output`}</a>
        {`
in minFraud custom rules.`}</p>
      <p>{`The minFraud service retains a record of when an email address or email domain
was first seen on the minFraud Network. An email address that has been
conducting transactions for a long time across the minFraud Network may be more
trustworthy than a new email address created within the last 30 days.`}</p>
      <p>{`You can select the email first seen output as a parameter in custom rules by
selecting minFraud outputs > Email first seen when defining a new condition for
a custom rule.`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408362750875#h_01HBNQ01QST1MPM481ZW520R7J",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`Learn more about setting custom rule conditions on our Knowledge Base.`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408562891803-Email-Risk-Data",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`Learn more about email risk data on our Knowledge Base.`}</a></li>

      </ul>
    </ReleaseNote>
    <ReleaseNote date="2024-04-08" title="Upcoming changes to our TLS certificates may impact customers with unusual server configuration" mdxType="ReleaseNote">
      <p>{`Starting in May, Let's Encrypt will no longer use a cross-signed root
certificate, and the primary TLS certificate handling the `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`*.maxmind.com`}</inlineCode>
        {`
domains will be impacted by this change.`}</p>
      <p><strong {...{
          "parentName": "p"
        }}>{`Most customers will see no impact from this change.`}</strong></p>
      <p>{`This change should only be of concern if the servers interacting with MaxMind
domains are running a very old or out of date operating system, or if you manage
your own local Certificate Authority store.`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "https://blog.cloudflare.com/upcoming-lets-encrypt-certificate-chain-change-and-impact-for-cloudflare-customers",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`Read more about these changes on the Cloudflare blog.`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "https://letsencrypt.org/docs/certificate-compatibility/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`Get information about compatibility from Let's Encrypt.`}</a></li>

      </ul>
    </ReleaseNote>
    <ReleaseNote date="2024-03-13" title="API policies are now permanently enforced" mdxType="ReleaseNote">
      <p>{`To improve our server infrastructure and allow for better performance and
efficiency, our API policies are now being permanently enforced as of March
13, 2024.`}</p>
      <p><strong {...{
          "parentName": "p"
        }}>{`What are the policies?`}</strong></p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`MaxMind only accepts API and database download requests sent with the more
secure HTTPS protocol.`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`MaxMind only accepts API and database download requests that are sent to the
appropriate hostname as documented in the integration instructions on our
Developer Portal (see direct links below).`}</li>

      </ul>
      <p><strong {...{
          "parentName": "p"
        }}>{`What do I need to do?`}</strong>
        {` Ensure that you are using the correct hostname for
your API requests, and that you are using HTTPS. Failure to do so will result in
web service or database download requests failing.`}</p>
      <p>{`You can view the appropriate URIs for minFraud services on our Developer Portal
using the links below:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "/minfraud/api-documentation/requests#service-endpoints",
            "parentName": "li"
          }}>{`minFraud Score, Insights, and Factors web services`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "/minfraud/track-devices/#implementation",
            "parentName": "li"
          }}>{`minFraud Device Tracking`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "/minfraud/report-a-transaction#api-documentation",
            "parentName": "li"
          }}>{`minFraud Transaction Reporting`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "/minfraud/minfraud-legacy#http-api",
            "parentName": "li"
          }}>{`Legacy minFraud web services`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "/minfraud/proxy-detection#http-based-api",
            "parentName": "li"
          }}>{`Legacy Proxy Detection web service`}</a></li>

      </ul>
      <p><strong {...{
          "parentName": "p"
        }}>{`Please note:`}</strong>
        {` This enforcement will also affect GeoIP web service and
database download requests. If you are also a GeoIP user,
`}
        <a {...{
          "href": "/geoip/release-notes/2024#api-policies-are-now-permanently-enforced",
          "parentName": "p"
        }}>{`see our GeoIP release note on this issue`}</a>
        {`.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2024-01-25" title="API policies - temporary enforcement on February 7, 2024" mdxType="ReleaseNote">
      <p>{`To improve our server infrastructure and allow for better performance and
efficiency, MaxMind will begin enforcing our policies around our API and
database download requests on March 13, 2024. To help customers get ready for
this change, we will have a planned, temporary enforcement of these policies on
February 7, 2024.`}</p>
      <p><strong {...{
          "parentName": "p"
        }}>{`What are the policies?`}</strong></p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`MaxMind will only accept API and database download requests sent with the more
secure HTTPS protocol.`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`MaxMind will only accept API and database download requests that are sent to
the appropriate hostname as documented in the integration instructions on our
Developer Portal (see direct links below).`}</li>

      </ul>
      <p><strong {...{
          "parentName": "p"
        }}>{`What do I need to do?`}</strong>
        {` To ensure that your MaxMind service is not
interrupted, please ensure ensure that you are using the correct hostname for
your API requests, and that you are using HTTPS, prior to February 7, 2024.`}</p>
      <p>{`If you have not made the requested changes before Wednesday, February 7, 2024,
you might experience a period where web service or database download requests
fail.`}</p>
      <p>{`You can view the appropriate URIs for minFraud services on our Developer Portal
using the links below:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "/minfraud/api-documentation/requests#service-endpoints",
            "parentName": "li"
          }}>{`minFraud Score, Insights, and Factors web services`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "/minfraud/track-devices/#implementation",
            "parentName": "li"
          }}>{`minFraud Device Tracking`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "/minfraud/report-a-transaction#api-documentation",
            "parentName": "li"
          }}>{`minFraud Transaction Reporting`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "/minfraud/minfraud-legacy#http-api",
            "parentName": "li"
          }}>{`Legacy minFraud web services`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "/minfraud/proxy-detection#http-based-api",
            "parentName": "li"
          }}>{`Legacy Proxy Detection web service`}</a></li>

      </ul>
      <p><strong {...{
          "parentName": "p"
        }}>{`Please note:`}</strong>
        {` This enforcement also affects GeoIP API requests. If you are
also a GeoIP user,
`}
        <a {...{
          "href": "/geoip/release-notes/2024#api-policies---temporary-enforcement-on-february-7-2024",
          "parentName": "p"
        }}>{`see our GeoIP release note on this issue`}</a>
        {`.`}</p>
    </ReleaseNote>
    <ReleaseNote date="2024-01-22" title="minFraud no longer accepts event times more than one year in the past" mdxType="ReleaseNote">
      <p>{`Starting tomorrow, January 23, 2024, minFraud will no longer accept
`}
        <inlineCode {...{
          "parentName": "p"
        }}>{`/event/time`}</inlineCode>
        {` inputs with values more than one year in the past. Most customers
do not need to send the `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`/event/time`}</inlineCode>
        {` input and will not be impacted by this
change.
`}
        <a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/5452006111003-Event-and-Account-Inputs#h_01G0Z3WBNAP5Y8WNRR156EJBBF",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Learn more about this input and how to use it to score historical transactions on our Knowledge Base.`}</a></p>
      <p>{`If you send the `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`/event/time`}</inlineCode>
        {` inputs with values more than one year in the past,
minFraud will:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`replace the event time with the current time`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`score the transaction and return a score`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`return an `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`INPUT_INVALID`}</inlineCode>
          {` warning with its response`}</li>

      </ul>
    </ReleaseNote>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      